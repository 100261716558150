import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {getSelectedTicketsWithPlaceInfo, getTotalSelectedTicketsQuantity} from '../../../../../selectors/seating'
import {isSeatingCollapsedAreaTicketsEnabled} from '../../../../../../../../commons/selectors/experiments'
import {getInvoice} from '../../../../../selectors/placed-order'
import {FooterRuntimeProps, FooterOwnProps} from './interfaces'
import {Footer as Presentation} from './footer'

const mapRuntime = ({state, actions: {setShowAccessibilityMode}}: DetailsPageAppProps): FooterRuntimeProps => ({
  selectedTicketsQuantity: isSeatingCollapsedAreaTicketsEnabled(state)
    ? getTotalSelectedTicketsQuantity(state)
    : getSelectedTicketsWithPlaceInfo(state).length,
  invoice: getInvoice(state),
  showAccessibilityMode: state.seating.showAccessibilityMode,
  setShowAccessibilityMode,
})

export const Footer = connect<FooterOwnProps, FooterRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
